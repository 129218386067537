import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Work.css';
import Swipper from '../Swipper/Swipper';
import worksData from '../../Data/Works'


function Work({workId}) {
  const [hidden, setHidden] = useState(true);
  const [projectIndex, setprojectIndex] = useState(workId);
  const projectsLength = worksData.works.length;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [WorkElement, setWorkElement] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, []);
  
  useEffect(() => {
    const Work = document.querySelector('.Work');
    if (Work) {
      setWorkElement(Work.getBoundingClientRect().top);
    }
  }, []);


  useEffect(() => {
    if (!workId) {
      const interval = setInterval(() => {
        setprojectIndex(preevIndex => (preevIndex + 1) % projectsLength);
      }, 17000);
      
      return () => clearInterval(interval);
    }
  }, []);

  const next = () => {
    projectIndex+1 < projectsLength ? setprojectIndex(projectIndex+1) : setprojectIndex(0);
  }
  
  const back = () => {
    projectIndex-1 < 0 ? setprojectIndex(projectsLength-1) : setprojectIndex(projectIndex-1) ;
  }

  const getProjectsToDisplay = () => {
    const slicedProjects = worksData.works.slice(projectIndex, projectIndex + 3);
    if (slicedProjects.length < 3) {
      const remainingProjects = worksData.works.slice(0, 3 - slicedProjects.length);
      return [...slicedProjects, ...remainingProjects];
    }
    return slicedProjects;
  };

  const displayedProjects = getProjectsToDisplay();
  return (
    <div id='work' className="Work">
      <h1 className='title'>Nos Travaux</h1>
      <div className={WorkElement < scrollPosition+400 ? "WorkContainer animate"  : "WorkContainer"}>
        <div className="cards">
          {displayedProjects.map((project, index) => (
            <div 
              key={index}
              className={`card ${index == 1 ? 'active' : ''}`}
              onMouseEnter={() => index == 1 && setHidden(false)} 
              onMouseLeave={() => index == 1 && setHidden(true)} 
              style={{ 
                background: `url(${project.images[0]}) no-repeat center / cover ,
                ${index === 1 ? 'linear-gradient(to top, rgba(0, 0, 0, 70%), rgba(0, 0, 0, 0))' : 'rgba(0, 0, 0, 70%)'}`,
              }}
              onClick={next}
            >
              <div className="cardContent">
                <h3>{project.name}</h3>
                <div className="button">
                  {index === 1 && <Link to={`/Details?workId=${projectIndex+1}`}>Afficher les détails <i className="fa-solid fa-arrow-right"></i></Link>} 
                </div>
              </div>
              { !hidden & index == 1 & false ? <div className="hover"><Link to={`/Details?workId=${projectIndex+1}`}><i className="fa-solid fa-arrow-right"></i></Link></div> : '' }
            </div>
          ))}
        </div>
      </div>
      <Swipper next={next} back={back} imageIndex={projectIndex} bgImages={worksData.works}/>
    </div>
  );
}

export default Work;
