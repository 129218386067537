import { useState, useEffect } from 'react';

import './Reviews.css';
import Swipper from '../Swipper/Swipper';


function Reviews() {
  const reviewsData =  [
    {
      name: "Naima K.",
      review: "J'ai eu une expérience incroyable avec BetyDecor ! Très professionnelle et à l'écoute, elle a transformé mon salon en un espace moderne et chaleureux. Je recommande vivement !"
    },
    {
      name: "Sarah M.",
      review: "La designer de BetyDecor est incroyable ! Elle a parfaitement compris mes goûts et a créé une cuisine fonctionnelle et élégante. Le rendu 3D m'a beaucoup aidé à visualiser le projet. Merci encore !"
    },
    {
      name: "Adil D.",
      review: "Je suis extrêmement satisfait du service de BetyDecor. Le respect des délais et la qualité du travail sont irréprochables. Mon bureau est maintenant un espace de travail inspirant et agréable. Je la recommande sans hésitation."
    }
  ];

  const [reviewIndex, setreviewIndex] = useState(0);
  const reviewsLength = reviewsData.length;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [ReviewsElement, setReviewsElement] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, []);
  
  useEffect(() => {
    const Reviews = document.querySelector('.Reviews');
    if (Reviews) {
      setReviewsElement(Reviews.getBoundingClientRect().top);
    }
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setreviewIndex(preevIndex => (preevIndex + 1) % reviewsLength);
    }, 17000);

    return () => clearInterval(interval);
  }, []);

  const next = () => {
    reviewIndex+1 < reviewsLength ? setreviewIndex(reviewIndex+1) : setreviewIndex(0);
  }
  
  const back = () => {
    reviewIndex-1 < 0 ? setreviewIndex(reviewsLength-1) : setreviewIndex(reviewIndex-1) ;
  }

  const getreviewsToDisplay = () => {
    const slicedreviews = reviewsData.slice(reviewIndex, reviewIndex + 3);
    if (slicedreviews.length < 3) {
      const remainingreviews = reviewsData.slice(0, 3 - slicedreviews.length);
      return [...slicedreviews, ...remainingreviews];
    }
    return slicedreviews;
  };

  const displayedReviews = getreviewsToDisplay();
  
  return (
    <div className="Reviews">
      <h1 className='title'>ce que disent nos clients</h1>
      <div className={ReviewsElement < scrollPosition+400 ? "ReviewsContainer animate"  : "ReviewsContainer"}>
        <div className="cards">
          {displayedReviews.map((review, index) => (
            <div
            key={index} 
            className={`card ${ index == 1 && 'active'}`}
            onClick={next}>
              <p>{review.review}</p>
              <h3>{review.name}</h3>
            </div>
          ))}
        </div>
      </div>
      <Swipper next={next} back={back} imageIndex={reviewIndex} bgImages={reviewsData}/>
    </div>
  );
}

export default Reviews;
