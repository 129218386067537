import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Details from './Pages/Details/Details';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './Pages/TermsOfUse/TermsOfUse';
import Home from './Pages/Home/Home';
import NotFound404 from './Pages/NotFound404/NotFound404';

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Details" element={<Details />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/TermsOfUse" element={<TermsOfUse />} />
            <Route path="*" element={<NotFound404 />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
