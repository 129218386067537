import { useState, useEffect } from 'react';

import './Home.css';
import Hero from '../../Componants/Hero/Hero';
import About from '../../Componants/About/About';
import Work from '../../Componants/Work/Work';
import Reviews from '../../Componants/Reviews/Reviews';
import Faqs from '../../Componants/Faqs/Faqs';
import Contact from './../../Componants/Contact/Contact';
import Footer from '../../Componants/Footer/Footer';
import Sidebar from '../../Componants/Sidebar/Sidebar';
import Navbar from '../../Componants/Navbar/Navbar';
import WhatsappButton from './../../Componants/WhatsappButton/WhatsappButton';
import Services from '../../Componants/Services/Services';

function Home() {
  const [toggleSidebar, settoggleSidebar] = useState('');

  return (
    <div className="Home">
      <WhatsappButton />
      <Navbar toggleSidebar={toggleSidebar} settoggleSidebar={settoggleSidebar}/>
      {toggleSidebar && <Sidebar/>}
      <Hero/>
      <About/>
      <Work workId={false} />
      <Services/>
      <Reviews/>
      <Faqs/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default Home;
