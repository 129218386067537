import { useState, useEffect } from 'react';
import './WhatsappButton.css';

function WhatsappButton() {

  return (
    <>
      <a className="WhatsappButton" target='_blank' href='https://wa.me/+212669012521'><i className='fa-brands fa-whatsapp'></i></a>
    </>
  );
}

export default WhatsappButton;
