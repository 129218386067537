import { useState, useEffect } from 'react';
import logo from '../../assets/images/logo/BetyDecor-Logo-Transparent.png';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  
  return (
    <div className="Footer">
      <div className="FooterContainer">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="content">
          <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/PrivacyPolicy">Politique de confidentialité</Link></li>
            <li><Link to="/TermsOfUse">Conditions d'utilisation</Link></li>
          </ul>
          <p>© 2024 BetyDecor. Tous droits réservés.
            <br></br>
            Made by <a target='_blank' href='https://redagrain.website/'>Reda Grain.</a>
          </p>
        </div>
        <div className="socialMedia">
          <a target='_blank' href="https://www.facebook.com/betydecorcom"><i class="fa-brands fa-facebook"></i></a>
          <a target='_blank' href="https://www.instagram.com/betydecorcom/"><i class="fa-brands fa-instagram"></i></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
