import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import './PrivacyPolicy.css';

import Footer from '../../Componants/Footer/Footer';
import Sidebar from '../../Componants/Sidebar/Sidebar';
import Navbar from '../../Componants/Navbar/Navbar';
import WhatsappButton from '../../Componants/WhatsappButton/WhatsappButton';



function PrivacyPolicy() {  
  const [toggleSidebar, settoggleSidebar] = useState('');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WhatsappButton />
      <Navbar toggleSidebar={toggleSidebar} settoggleSidebar={settoggleSidebar}/>
      {toggleSidebar && <Sidebar/>}
      <div className="PrivacyPolicy">
          <div className="PrivacyPolicyContainer">
            <div className="PrivacyPolicyHeader">
              <h1 className='title'>politique de confidentialité</h1>
              <p>Dernière mise à jour : 19/06/2024</p>
            </div>
            <div className="PrivacyPolicyContent">
              <p>Chez BetyDecor, nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles. Voici quelques détails supplémentaires sur notre politique de confidentialité :</p>
              <h2>Collecte d'informations personnelles</h2>
              <p>Chez BetyDecor, nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles. Voici quelques détails supplémentaires sur notre politique de confidentialité :</p>
              <h2>Utilisation des informations personnelles</h2>
              <p>Nous utilisons vos informations personnelles pour répondre à votre message et vous fournir les informations ou services que vous avez demandés. Nous pouvons également utiliser vos informations personnelles pour vous envoyer des informations marketing, mais uniquement avec votre consentement.</p>
              <h2>Modifications de cette politique de confidentialité</h2>
              <p>Nous pouvons modifier cette politique de confidentialité de temps à autre. Nous vous informerons de toute modification en publiant la politique de confidentialité révisée sur notre site Web.</p>
            </div>
          </div>
      </div>
      <Footer/>
    </>
  );
}

export default PrivacyPolicy;
