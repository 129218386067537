import { useState, useEffect } from 'react';

import './Contact.css';

function Contact() {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [ContactElement, setContactElement] = useState(0);

  const [Message, setMessage] = useState('');


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, []);
  
  useEffect(() => {
    const Contact = document.querySelector('.Contact');
    if (Contact) {
      setContactElement(Contact.getBoundingClientRect().top);
    }
  }, []);

  
  return (
    <div id='contact' className="Contact">
      <h1 className='title'>Contactez-nous</h1>
      <div className={ContactElement < scrollPosition+400 ? "ContactContainer animate"  : "ContactContainer"}>
        <div className="contactContent">
          <div className="content">
            <h3>Contactez-nous</h3>
            <p>Prêt à transformer votre espace avec BetyDecor ? <br></br> Contactez-nous dès aujourd'hui pour une consultation et laissez-nous vous aider à réaliser vos rêves de design d'intérieur.</p>
          </div>
          <div className="details">
            <ul>
              <li><i className="fa-solid fa-fax"></i><a target='_blank' href="tel: +212 7 13 22 97 46">+212 7 13 22 97 46</a></li>
              <li><i className="fa-solid fa-envelope"></i><a target='_blank' href="mailto:contact@betydecor.com">contact@betydecor.com</a></li>
              <li><i className="fa-solid fa-location-dot"></i><a href="">Tangier, Morocco</a></li>
            </ul>
          </div>
        </div>
        <div className="contactForm">
          <p>Contactez-nous en envoyant un message via WhatsApp, et nous vous contacterons dans les plus brefs délais !</p>
          <textarea id="Message" onChange={(e) => setMessage(e.currentTarget.value)} placeholder='Message'>{Message}</textarea>
          <div className="button"> 
              <a href={'https://wa.me/+212669012521?text=' + encodeURIComponent(Message)} target='_blank' ><i className="fa-brands fa-whatsapp"></i> Envoyer</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
