import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound404.css';

const NotFound = () => (
  <div className="not-found">
    <div className="not-found-container">
      <h1>404 Page non trouvée</h1>
      <p>Désolé, la page que vous recherchez n'existe pas.</p>
      <div className="button">
        <Link to="/">Retourner à l'accueil</Link>
      </div>
    </div>
  </div>
);

export default NotFound;
