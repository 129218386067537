import './Navbar.css';
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Navbar({toggleSidebar, settoggleSidebar}) {
  const [sectionName, setsectionName] = useState('');

  function getHash()  {
    const hash = window.location.hash;
    if (hash.startsWith('#')) {
        const name = hash.substring(1);
        setsectionName(name);
    }
  };


  return (
    <div className="navbar">
      <div className="navbarContainer">
        <h1><Link to='/'>BetyDecor</Link></h1>
        <div className="menu">
          <ul>
            <li onClick={getHash} className={sectionName == 'hero' ? 'active' : ''} ><HashLink to='/#hero'>Accueil</HashLink></li>
            <li onClick={getHash} className={sectionName == 'about' && 'active'}><HashLink to='/#about'>À propos</HashLink></li>
            <li onClick={getHash} className={sectionName == 'work' && 'active'}><HashLink  to='/#work'>Nos travaux</HashLink></li>
            <li onClick={getHash} className={sectionName == 'services' && 'active'}><HashLink  to='/#services'>Nos services</HashLink></li>
            <li onClick={getHash} className={sectionName == 'contact' && 'active'}><HashLink to='/#contact'>Contact</HashLink></li>
          </ul>
          <i className={toggleSidebar ? 'fa-solid fa-circle-xmark' : 'fa-solid fa-bars-staggered'} onClick={()=>settoggleSidebar(!toggleSidebar)}></i>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
