import './Hero.css';
import Swipper from '../Swipper/Swipper';
import { useState, useEffect } from 'react';
import terasse from '../../assets/images/terasse.jpg';
import chambre from '../../assets/images/chambre.jpg';
import cuisine from '../../assets/images/cuisine.jpg';
import salon from '../../assets/images/salon-marocain.jpg';
import reception from '../../assets/images/reception.jpg';
import reception_hotel from '../../assets/images/reception-hotel2.jpeg';

function Hero() {
  const bgImages = [salon,terasse, chambre, cuisine, reception, reception_hotel];
  const imgLength = bgImages.length;
  const [imageIndex, setimageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setimageIndex(preevIndex => (preevIndex + 1) % imgLength);
    }, 9000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [imgLength]);

  const next = () => {
    imageIndex+1 < imgLength ? setimageIndex(imageIndex+1) : setimageIndex(0);
  }
  
  const back = () => {
    imageIndex-1 < 0 ? setimageIndex(imgLength-1) : setimageIndex(imageIndex-1) ;
  }

  return (
    <div className="hero" id='hero' style={{ background: `url(${bgImages[imageIndex]}) rgba(0, 0, 0, 50%) no-repeat center`}}>
        <div className="heroContainer animate">
          <h1><span>Interior</span><br/>Design</h1>
          <p>Toujours choisir le meilleur</p>
          <div className="button">
              <a href="#work">EXPLORER</a>
          </div>
        </div>
        <Swipper next={next} back={back} imageIndex={imageIndex} bgImages={bgImages}/>
    </div>
  );
}

export default Hero;
