import './Sidebar.css';
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

function Sidebar() {
  const [sectionName, setsectionName] = useState('');

  function getHash()  {
    const hash = window.location.hash;
    if (hash.startsWith('#')) {
        const name = hash.substring(1);
        setsectionName(name);
    }
  };


  return (
    <div className="Sidebar">
      <div className="SidebarContainer">
        <ul>
          <li onClick={getHash} className={sectionName == 'hero' || sectionName == '' ? 'active' : ''} ><HashLink to='/#hero'>Accueil</HashLink></li>
          <li onClick={getHash} className={sectionName == 'about' && 'active'}><HashLink to='/#about'>À propos</HashLink></li>
          <li onClick={getHash} className={sectionName == 'work' && 'active'}><HashLink  to='/#work'>Nos travaux</HashLink></li>
          <li onClick={getHash} className={sectionName == 'services' && 'active'}><HashLink  to='/#services'>Nos services</HashLink></li>
          <li onClick={getHash} className={sectionName == 'contact' && 'active'}><HashLink to='/#contact'>Contact</HashLink></li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
