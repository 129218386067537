import { useState, useEffect } from 'react';

import './Services.css';
import chambre from '../../assets/images/chambre.jpg';
import suivi_de_chantier from '../../assets/images/suivi_de_chantier.jpg';
import plan2d from '../../assets/images/plan2d.jpg';
import Realisation_des_travaux from '../../assets/images/Realisation_des_travaux.jpg';
import visualisation3d from '../../assets/images/visualisation3d.jpeg';
import Swipper from '../Swipper/Swipper';


function Services() {
  const services = [
    {
        name: "Plan 2D",
        imageUrl: plan2d
    },
    {
        name: "Visualisation 3D",
        imageUrl: visualisation3d
    },
    {
        name: "Réalisation des travaux",
        imageUrl: Realisation_des_travaux
    },
    {
        name: "Suivi de chantier",
        imageUrl: suivi_de_chantier
    },
  ];

  const [hidden, setHidden] = useState(true);
  const [serviceIndex, setserviceIndex] = useState(0);
  const servicesLength = services.length;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [ServicesElement, setServicesElement] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, []);
  
  useEffect(() => {
    const Services = document.querySelector('.Services');
    if (Services) {
      setServicesElement(Services.getBoundingClientRect().top);
    }
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setserviceIndex(preevIndex => (preevIndex + 1) % servicesLength);
    }, 17000);

    return () => clearInterval(interval);
  }, []);

  const next = () => {
    serviceIndex+1 < servicesLength ? setserviceIndex(serviceIndex+1) : setserviceIndex(0);
  }
  
  const back = () => {
    serviceIndex-1 < 0 ? setserviceIndex(servicesLength-1) : setserviceIndex(serviceIndex-1) ;
  }

  const getservicesToDisplay = () => {
    const slicedservices = services.slice(serviceIndex, serviceIndex + 3);
    if (slicedservices.length < 3) {
      const remainingservices = services.slice(0, 3 - slicedservices.length);
      return [...slicedservices, ...remainingservices];
    }
    return slicedservices;
  };

  const displayedservices = getservicesToDisplay();
  return (
    <div id='services' className="Services">
      <h1 className='title'>Nos Services</h1>
      <div className={ServicesElement < scrollPosition+400 ? "ServicesContainer animate"  : "ServicesContainer"}>
        <div className="cards">
          {displayedservices.map((service, index) => (
            <div 
              key={index}
              className={`card ${index == 1 ? 'active' : ''}`}
              onMouseEnter={() => index == 1 && setHidden(false)} 
              onMouseLeave={() => index == 1 && setHidden(true)} 
              style={{ 
                background: `url(${service.imageUrl}) no-repeat center / cover ,
                ${index === 1 ? 'linear-gradient(to top, rgba(0, 0, 0, 70%), rgba(0, 0, 0, 0))' : 'rgba(0, 0, 0, 70%)'}`,
              }}
              onClick={next}
            >
              <h3>{service.name}</h3>
            </div>
          ))}
        </div>
      </div>
      <Swipper next={next} back={back} imageIndex={serviceIndex} bgImages={services}/>
    </div>
  );
}

export default Services;
