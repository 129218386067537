import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import './TermsOfUse.css';

import Footer from '../../Componants/Footer/Footer';
import Sidebar from '../../Componants/Sidebar/Sidebar';
import Navbar from '../../Componants/Navbar/Navbar';
import WhatsappButton from '../../Componants/WhatsappButton/WhatsappButton';



function TermsOfUse() {  
  const [toggleSidebar, settoggleSidebar] = useState('');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WhatsappButton />
      <Navbar toggleSidebar={toggleSidebar} settoggleSidebar={settoggleSidebar}/>
      {toggleSidebar && <Sidebar/>}
      <div className="TermsOfUse">
          <div className="TermsOfUseContainer">
            <div className="TermsOfUseHeader">
              <h1 className='title'>Conditions d'utilisation</h1>
              <p>Dernière mise à jour : 19/06/2024</p>
            </div>
            <div className="TermsOfUseContent">
              <p>BetyDecor ("BetyDecor", "nous", "notre", "nôtre") vous fournit ce site Web et les services connexes (collectivement, les "Services") sous réserve des présentes conditions d'utilisation ("Conditions"). En accédant ou en utilisant les Services, vous acceptez d'être lié par ces Conditions. Si vous n'acceptez pas ces Conditions, vous n'êtes pas autorisé à accéder ou à utiliser les Services.</p>
              <h2>Restrictions d'utilisation</h2>
              <p>Vous n'êtes pas autorisé à utiliser les Services à des fins illégales ou nuisibles. Vous n'êtes pas autorisé à utiliser les Services d'une manière qui pourrait endommager, désactiver, surcharger ou altérer les Services ou interférer avec l'utilisation des Services par d'autres personnes. Vous n'êtes pas autorisé à obtenir ou à tenter d'obtenir un accès non autorisé aux Services ou à toute information ou tout matériel qui n'est pas explicitement rendu disponible par BetyDecor.</p>
              <h2>Propriété intellectuelle</h2>
              <p>Les Services et tout le contenu et les informations disponibles sur ou via les Services (y compris, mais sans s'y limiter, le texte, les graphiques, les images, les logos, les noms commerciaux, les marques de service et les brevets) sont la propriété de BetyDecor ou de ses concédants de licence et sont protégés par les lois sur le droit d'auteur, les marques de commerce et autres lois sur la propriété intellectuelle. Vous n'êtes pas autorisé à utiliser le contenu ou les informations des Services sans l'autorisation écrite préalable de BetyDecor ou de son concédant de licence.</p>
              <h2>Général</h2>
              <p>Ces Conditions constituent l'intégralité de l'accord entre vous et BetyDecor concernant les Services et remplacent toutes les communications, accords ou ententes antérieurs.</p>
            </div>
          </div>
      </div>
      <Footer/>
    </>
  );
}

export default TermsOfUse;
