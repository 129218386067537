import './About.css';
import img1 from '../../assets/images/aboutUs-img.png';
import { useState, useEffect } from 'react';



function About() {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [aboutElement, setaboutElement] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, []);
  
  useEffect(() => {
    const about = document.querySelector('.about');
    if (about) {
      setaboutElement(about.getBoundingClientRect().top);
    }
  }, []);
  
  return (
    <div id='about' className="about">
        <div className={aboutElement < scrollPosition+300 ? "aboutContainer animate" : "aboutContainer"}>
          <div className="content">
            <h1 className='title'>À propos de nous</h1>
            <p>Bienvenue chez BetyDecor, votre agence de design d'intérieur située dans la magnifique ville de Tanger. Nous sommes passionnés par l'art de transformer des espaces ordinaires en lieux extraordinaires, où chaque détail compte et chaque projet est une œuvre d'art unique.</p>
          </div>
          <div className="aboutImage">
            <img src={img1} alt="About Us Image" />
          </div>
        </div>
    </div>
  );
}

export default About;
