import './Swipper.css';

function Swipper({next, back, imageIndex, bgImages}) {
  return (
    <div className="swipper">
      <div className="chevrons">
        <i onClick={back} class="fa-solid fa-chevron-left"></i>
        <i onClick={next} class="fa-solid fa-chevron-right"></i>
      </div>
      <ul>
        { bgImages.map((item, index) => (
           <li className={index == imageIndex && 'active'}></li>
        )) }
      </ul>
    </div>
  );
}

export default Swipper;
