import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import worksData from '../../Data/Works';

import './Details.css';
import Work from '../../Componants/Work/Work';
import Footer from '../../Componants/Footer/Footer';
import Sidebar from '../../Componants/Sidebar/Sidebar';
import Navbar from '../../Componants/Navbar/Navbar';
import WhatsappButton from './../../Componants/WhatsappButton/WhatsappButton';
import Contact from '../../Componants/Contact/Contact';



function Details() {  
  const [toggleSidebar, settoggleSidebar] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let workId = searchParams.get('workId');

  useEffect(() => {
    window.scrollTo(0, 20);
  }, [workId]);

  if (!workId) {
    return <Navigate to='/NotFound404'  />
  }

  workId = worksData.works.length == workId ? 0 : workId;



  return (
    <>
      <WhatsappButton />
      <Navbar toggleSidebar={toggleSidebar} settoggleSidebar={settoggleSidebar}/>
      {toggleSidebar && <Sidebar/>}
      <div className="Details">
       <div className="detailsContainer">
        <div className="title">détails</div>
        <div className="detailsHeader">
          <div className="card">
            <h2>Projet</h2>
            <h3>{worksData.works[workId].name}</h3>
          </div>
          <div className="card">
            <h2>Lieu</h2>
            <h3>{worksData.works[workId].lieu}</h3>
          </div>
        </div>
        <div className="garelly">
          {worksData.works[workId].images.map((image, index) => (
            <div className={`card ${(index == 1 ||  index == 2 || index == 5 || index == 6) && 'active'}`}><img src={image} key={index} alt="" /></div>
          ))}
        </div>
       </div>
      </div>
      <Work workId={workId-1}/>
      <Contact/>
      <Footer/>
    </>
  );
}

export default Details;
